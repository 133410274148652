<template>
  <header id="header" class="header">
    <div class="header content">
      <router-link to="/" class="logo">
        <img class="logo-image" src="@/assets/images/icons/logo.svg" alt="logo">
        <span class="text"> Займы Онлайн </span>
      </router-link>
      <div class="timer-cont">
        <span class="text">Деньги у вас через:</span>
        <v-timer/>
      </div>
    </div>
  </header>
</template>

<script>
import vTimer from "@/components/UI/vTimer.vue";
export default {
  components: {vTimer}
}
</script>

<style lang="sass" >
@import "@/assets/styles/base/adaptive"
#header
  background: var(--start-color)
  display: flex
  justify-content: center
  .content
    position: absolute
    top: 0
    z-index: 100
    padding-top: 40px
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    .logo
      display: flex
      flex-direction: row
      gap: 20px
      align-items: center
      .text
        color: var(--main-color)
        font-size: 1.5rem
        font-weight: 600
    .timer-cont
      display: flex
      flex-direction: row
      align-items: center
      gap: 30px
      .text
        color: var(--main-color)
        font-size: 2rem
        font-weight: 500
@media only screen and (max-width: $bp-pc)
  #header
    .content
      padding-top: 30px
      .logo
        gap: 15px
        .logo-image
          width: 36px
          height: 36px
        .text
          font-size: 1.25rem
      .timer-cont
        gap: 15px
        .text
          font-size: 1.25rem
@media only screen and (max-width: $bp-tablet)
  #header
    .content
      flex-direction: column
      padding-top: 22px
      gap: 10px
      .logo
        gap: 15px
        .logo-image
          width: 24px
          height: 24px
        .text
          font-size: 1rem
      .timer-cont
        gap: 10px
        .text
          font-size: 1rem
</style>