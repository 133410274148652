<template>
  <footer id="footer" class="footer">
    <div class="content">
      <div class="logo">
        <img src="@/assets/images/icons/logo-transp.svg" alt="">
        <span class="text">Займы Онлайн</span>
      </div>
      <div class="policy">
        <a class="text" href="">Политика конфиденциальности</a>
      </div>
      <span class="info">
        <strong>Займы онлайн</strong> — Это бесплатный информационный сервис, предоставляющий  справочную информацию о  займах. Используя его вы сможете быстро решить свои финансовые вопросы, затратив минимум своего личного времени.
        Все товарные знаки и логотипы, представленные на этом сайте, являются собственностью соответствующих владельцев и взяты из публичных источников. Их появление на этом сайте не подразумевает одобрение ими нашего продукта.
        Отказ от ответственности:
        Сервис не является кредитором или ипотечным/кредитным брокером и не предоставляет финансовые услуги прямо или косвенно через представителей или агентов. Не осуществляет выдачу каких-либо видов кредита. Не несет ответственности за точность информации, предоставленной банками по тарифам, кредитным ставкам, переплатам, а также за любую другую.
      </span>
      <span class="text copyright">
        © Займы Онлайн, {{currentYear}}
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear: function () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.footer
  background: var(--main-color)
  .content
    margin: 0 auto
    padding-top: 40px
    padding-bottom: 40px
    display: grid
    grid-gap: 60px
    grid-template-areas: 'logo policy' 'info info' 'copy copy'
    .logo
      grid-area: logo
      display: flex
      flex-direction: row
      align-items: center
      gap: 20px
      .text
        color: #FFF
        font-size: 1.5rem
        font-weight: 600
    .policy
      grid-area: policy
      display: flex
      justify-content: end
      .text
        align-self: end
        color: white
        font-size: 1.25rem
        font-weight: 500
    .info
      grid-area: info
      color: #FFF
      font-size: 1.25rem
      font-weight: 400
      strong
        color: white
        font-size: 1.375rem
    .copyright
      grid-area: copy
      color: white
      text-align: end
      align-self: end
@media only screen and (max-width: $bp-pc)
  .footer
    background: var(--main-color)
    .content
      margin: 0 auto
      grid-gap: 40px
      .logo
        display: flex
        flex-direction: row
        align-items: center
        gap: 15px
        img
          width: 36px
          height: 36px
        .text
          color: #FFF
          font-size: 1.25rem
      .policy
        align-self: center
        .text
          font-size: 1rem
      .info
        font-size: 0.875rem
        strong
          font-size: 1rem
      .copyright
        font-size: 0.75rem

@media only screen and (max-width: $bp-tablet)
  .footer
    .content
      margin: 0 auto
      grid-gap: 30px
      grid-template-areas: 'logo logo' 'info info' 'policy policy' 'copy copy'
      .logo
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        gap: 15px
        img
          width: 24px
          height: 24px
        .text
          font-size: 1rem
      .info
        text-align: center
        font-size: 0.8125rem
        strong
          font-size: 0.9375rem
      .policy
        margin-top: 30px
        justify-content: center
        .text
          font-size: 0.8125rem
      .copyright
        margin-top: -10px
        display: flex
        justify-content: center

</style>