<template>
  <Head>
    <title>Займы Онлайн</title>
    <link rel="icon" href="/favicon.ico">
    <link rel="icon" href="/favicon.png">
    <meta name="description" content="" />
  </Head>
  <div class="home-page">
    <v-modal v-if="showModal === true" @closeModal="closeModal"/>
    <section class="start">
      <div class="content">
        <h1 class="title">✅ Онлайн-заём на карту от новых МФО на банковскую карту.
          В честь Нового года одобрим всем!</h1>
        <div class="description">
          <h2 class="text">Одобрим даже с текущими просрочками более 90 дней. Подай заявку в течении 10 минут получи
            первый заём на 21 день под 0%</h2>
          <h2 class="text">✅ Займы без страховок и неожиданных списаний с карты</h2>
        </div>
        <div class="start-container">
          <v-calculator />
          <img class="decor-image1" src="@/assets/images/women-group.webp" alt="">
          <img class="decor-image2" src="@/assets/images/women-group2.webp" alt="">
        </div>
      </div>
      <img class="start-image" src="@/assets/images/background.webp" alt="">
    </section>

    <section class="intro">
      <div class="content">
        <div class="title-text">
          <span class="text">Всего за 10 минут</span>
          <h3 class="h3">Займ онлайн без проблем</h3>
        </div>
        <img class="intro-image" src="@/assets/images/intro-image.webp" alt="">
        <div class="steps">
          <div class="step_item">
            <img class="image" src="@/assets/images/icons/form.svg" alt="">
            <div class="step-info">
              <span class="name">Заполните анкету</span>
              <p class="text">Удобный интерфейс позволит быстро пройти процедуру заполнения</p>
            </div>
          </div>
          <div class="step_item">
            <img class="image" src="@/assets/images/icons/wait.svg" alt="">
            <div class="step-info">
              <span class="name">Дождитесь одобрения </span>
              <p class="text">Рассмотрение заявки займет не более 5 минут</p>
            </div>
          </div>
          <div class="step_item">
            <img class="image" src="@/assets/images/icons/money.svg" alt="">
            <div class="step-info">
              <span class="name">Получите деньги</span>
              <p class="text">На банковскую карту, наличными или на электронный кошелек </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="garant" id="garant">
      <div class="content">
        <div class="title-text">
          <span class="text">Гарантированные условия</span>
          <h3 class="h3">Почему обращаются к нам</h3>
        </div>
        <div class="garant-blocks">
          <div class="block">
            <div class="image-cont">
              <img class="image" src="@/assets/images/man1.webp" alt="">
              <img class="image-ext" src="@/assets/images/man1-ext.webp" alt="">
            </div>
            <span class="text">0% первый заём </span>
          </div>
          <div class="block">
            <div class="image-cont">
              <img class="image" src="@/assets/images/man2.webp" alt="">
              <img class="image-ext" src="@/assets/images/man2-ext.webp" alt="">
            </div>
            <span class="text">10 минут и деньги у вас</span>
          </div>
          <div class="block">
            <div class="image-cont">
              <img class="image" src="@/assets/images/man3.webp" alt="">
              <img class="image-ext" src="@/assets/images/man3-ext.webp" alt="">
            </div>
            <span class="text">99% одобрения</span>
          </div>
        </div>
      </div>
    </section>

    <section class="reviews">
      <div class="content">
        <div class="title-text">
          <span class="text">Нас рекомендуют</span>
          <h3 class="h3">Отзывы наших клиентов</h3>
        </div>
        <div class="nav-btns">
          <img class="prev" :class="{ 'disabled': !allowSwipePrev }" @click="swiperSwipeTo('prev')"
            src="@/assets/images/icons/arrow.svg" alt="">
          <img class="next" :class="{ 'disabled': !allowSwipeNext }" @click="swiperSwipeTo('next')"
            src="@/assets/images/icons/arrow.svg" alt="">
        </div>
        <swiper id="reviews-swiper" class="slider" :navigation="true" :centered-slides="false" :space-between="20"
          :breakpoints="{
            320: { spaceBetween: 10, slidesPerView: 1.1 },
            600: { spaceBetween: 20, slidesPerView: 2.2 },
            1200: { slidesPerView: 'auto' }
          }">
          <swiper-slide v-for="r in reviews" :key="r" class="slide">
            <div class="user-info">
              <img class="image" src="@/assets/images/man1.webp" alt="">
              <span class="name">{{ r.name }}</span>
            </div>
            <span class="text">{{ r.text }}</span>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="form">
      <div class="content">
        <div class="title-text">
          <span class="text">Для тех, кто не может ждать</span>
          <span class="h3">Заполните короткую форму и получите заём</span>
        </div>
        <div class="container">
          <v-calculator />
        </div>
      </div>
      <img class="form-image" src="@/assets/images/background.webp" alt="">
    </section>

    <section class="conditions">
      <div class="content">
        <div class="line">
          <span class="name">Условия получения:</span>
          <span class="text">Первый заём до 10 000 рублей выдается бесплатно в случае своевременного погашения; В случае
            нарушения сроков, размер неустойки составляет 0,10% от суммы просрочки в день, но не более 10% от суммы
            кредита; В случае длительной задержки выплаты информация будет передана в БКИ.</span>
        </div>
        <div class="line">
          <span class="name">Информация о полной стоимости займа и пример расчета:</span>
          <span class="text">ПСК (полная стоимость кредита) в процентах составляет от 0 до 292% годовых.
            <br>
            Пример:
            <br>
            Заём в размере 10 000 рублей выдан на 61 дней с пролонгацией. В году 365 дней, по договору ставка составляет
            0,8% в день (годовая ставка, соответственно будет равна 292%). Сумма процентов за год составляет 29 200 руб.
            (10 000*292% = 29 200), за 1 день 80 руб. (29 200/365 = 80), за 61 дней соответственно 4 880 руб. (80*61 = 4
            880. Общая сумма платежа составляет 14 880 руб. (10 000 руб. основной долг + 4 880 руб. проценты). Процентная
            ставка по займам составляет от 0 до 292% годовых; По потребительским кредитам и кредитным картам - от 17 до
            31% годовых;
          </span>
        </div>
        <div class="line">
          <span class="name">Информация о полной стоимости займа и пример расчета:</span>
          <span class="text">В случае невозвращения в условленный срок суммы кредита или суммы процентов за пользование
            заёмными средствами кредитор вынуждено начислит штраф за просрочку платежа. Большинство кредиторов идут на
            уступки и дают 3 дополнительных рабочих дня для оплаты. Они предусмотрены на случай, к примеру, если
            банковский перевод занял больше времени, чем обычно. Однако, в случае неполучения от Вас какой-либо реакции в
            течение продолжительного времени, будет начислен штраф за просрочку срока погашения размером в среднем 0,10%
            от первоначальной суммы для займов, 0,03% от суммы задолженности в среднем для потребительских кредитов и
            кредитных карт. При несоблюдении Вами условий по погашению кредитов и займов, данные о Вас могут быть переданы
            в реестр должников, а задолженность - коллекторскому агентству для взыскания долга. О всех приближающихся
            сроках платежа кредитор своевременно информирует Вас по СМС или электронной почте. Рекомендуем Вам вносить
            платеж в день получения данных напоминаний. Погашая задолженность в срок, Вы формируете хорошую кредитную
            историю, что повышает Ваши шансы в дальнейшем получить кредит на более выгодных условиях. Предложение не
            является офертой. Конечные условия уточняйте при прямом общении с кредиторами.</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
import VCalculator from "@/components/UI/vCalculator.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css'
import VModal from "@/components/vModal.vue";

export default {
  components: {VModal, VCalculator, Head, Swiper, SwiperSlide },
  data() {
    return {
      allowSwipeNext: true,
      allowSwipePrev: false,
      showModal: false,
      modalShown: false,
      reviews: [
        {
          name: "Кирилл К.",
          text: "Впервые пользовался такими услугами. Были опасения, но все получилось как нужно. Вернул займ через неделю без процентов."
        },
        {
          name: "Петр К. ",
          text: "Для заявки понадобился только паспорт. Одобрили и прислали деньги очень быстро. Спасибо займам онлайн, реально выручаете!"
        },
        {
          name: "Игорь К.",
          text: "Решил попробовать Займы Онлайн. Оформил заявку, и уже через 15 минут деньги были на карте. Вернул займ через месяц, проценты были небольшие. В целом, сервисом  доволен."
        },
        {
          name: "Ирина К.",
          text: "Брала деньги до зарплаты, заплатить за квартиру. Вернула в срок, поэтому лишних процентов никаких не было. Деньги пришли быстро"
        },
        {
          name: "Анастасия М.",
          text: "Реклама соответствует действительности. Оперативность на высшем уровне. За 15 минут я уже получила деньги. Отличные условия. Вернула все вовремя, проценты не переплатила."
        },
        {
          name: "Антон С.",
          text: "Пропустил свой самолет, нужно было срочно возвращаться домой. Воспользовался займом, через неделю вернул. Так как брал первый раз - вернул ровно столько же сколько взял."
        },
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    swiperSwipeTo(direction) {
      const swiperEl = document.getElementById('reviews-swiper').swiper;
      if (direction === 'prev') {
        swiperEl.slidePrev();
      } else {
        swiperEl.slideNext();
      }
      this.allowSwipeNext = !swiperEl.isEnd
      this.allowSwipePrev = !swiperEl.isBeginning
    },
    handleScroll() {
      const targetElement = document.getElementById('garant');
      const targetOffset = targetElement.offsetTop;
      const scrollY = window.scrollY;

      if (scrollY >= targetOffset) {
        if (this.modalShown === false) {
          this.showModal = true
          this.modalShown = true
          document.documentElement.style.overflowY = 'hidden'
        }
      } else {
        this.showModal = false
        document.documentElement.style.overflowY = 'auto'
      }
    },
    closeModal() {
      this.showModal = !this.showModal
      document.documentElement.style.overflowY = 'auto'
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.home-page
  .start
    background: var(--start-color)
    padding-top: 163px
    padding-bottom: 0
    margin-bottom: 75px
    position: relative
    overflow: hidden
    .start-container
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      .calculator
        height: max-content
        margin-bottom: 95px
      .decor-image1
        align-self: end
        width: 30%
      .decor-image2
        display: none
    .start-image
      position: absolute
      object-fit: cover
      top: 0
      left: 0
      opacity: 70%
      width: 100%
      height: 100%
    .content
      position: relative
      z-index: 20
      .title
        font-size: 2.75rem
        font-weight: 600
        line-height: 130%
        margin-bottom: 30px
      .description
        width: 60%
        display: flex
        flex-direction: column
        gap: 20px
        padding: 20px 30px
        margin-bottom: 60px
        border-left: 5px solid var(--accent-color)
        background: #FFF
        .text
          max-width: 725px
          color: var(--text-color)
          font-size: 1.375rem
  .intro
    .content
      display: grid
      grid-template-areas: 'title title' "img steps"
      grid-gap: 40px
      .title-text
        grid-area: title
      .intro-image
        grid-area: img
        align-self: end
      .steps
        grid-area: steps
        display: flex
        flex-direction: column
        gap: 30px
        .step_item
          display: flex
          flex-direction: row
          align-items: center
          padding: 30px
          background: var(--card-color)
          border-radius: 15px
          gap: 60px
          .step-info
            display: flex
            flex-direction: column
            gap: 30px
            .name
              font-size: 1.5rem
              font-weight: 600
            .text
              font-size: 1.25rem
              font-weight: 400
  .garant
    .content
      display: flex
      flex-direction: column
      gap: 40px
      .garant-blocks
        display: flex
        flex-direction: row
        gap: 20px
        justify-content: space-between
        .block
          display: flex
          flex-direction: row
          align-items: center
          gap: 30px
          border-radius: 15px
          border: 2px solid #2C9FFA
          background: #2C9FFA
          .image-cont
            .image
              border-top-left-radius: 15px
              border-bottom-left-radius: 15px
              object-fit: cover
            .image-ext
              display: none
          .text
            flex: 1 0 0
            max-width: 180px
            padding-right: 30px
            color: #FFF
            text-align: center
            font-size: 1.25rem
            font-weight: 600
  .reviews
    .content
      display: grid
      grid-gap: 30px 0
      grid-template-areas: 'title nav' 'slider slider'
      .title-text
        grid-area: title
      .nav-btns
        grid-area: nav
        display: flex
        flex-direction: row
        gap: 10px
        justify-content: flex-end
        align-items: flex-end
        .prev
          cursor: pointer
          width: 50px
          height: 50px
          padding: 10px
          border-radius: 100px
          background: var(--accent-color)
        .next
          cursor: pointer
          width: 50px
          height: 50px
          padding: 10px
          border-radius: 100px
          transform: rotate(180deg)
          background: var(--accent-color)
        .disabled
          background: var(--gray-lines-color)
          cursor: not-allowed
          pointer-events: none
      .slider
        grid-area: slider
        width: 100%
        display: block
        max-width: 100%
        overflow: visible
        .swiper-wrapper
          align-items: flex-start
          justify-content: flex-start
        .slide
          width: auto
          height: 100%
          display: flex
          padding: 30px
          border-radius: 15px
          background: var(--card-color)
          flex-direction: column
          gap: 20px
          .user-info
            display: flex
            flex-direction: row
            gap: 20px
            align-items: center
            .image
              width: 70px
              height: 70px
              border-radius: 50%
            .name
              font-size: 1.5rem
              font-weight: 600
          .text
            max-width: 380px
            font-size: 1.25rem
            font-weight: 400
  .form
    margin: 75px 0
    padding: 60px 0 150px
    display: flex
    align-items: center
    position: relative
    overflow: hidden
    .content
      position: relative
      z-index: 2
      display: flex
      flex-direction: column
      gap: 60px
      .title-text
        text-align: center
        align-items: center
      .h3
        max-width: 540px
      .container
        display: flex
        justify-content: center
    .form-image
      opacity: 70%
      object-fit: cover
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
  .conditions
    padding-bottom: 150px
    .content
      border-radius: 15px
      padding: 45px
      display: flex
      flex-direction: column
      gap: 40px
      background: var(--card-color)
      .line
        display: flex
        flex-direction: column
        gap: 20px
        .name
          font-size: 1.5rem
          font-weight: 600
        .text
          font-size: 1.25rem
          font-weight: 400
@media only screen and (max-width: $bp-pc)
  .home-page
    .start
      padding-top: 129px
      margin-bottom: 50px
      .content
        .title
          font-size: 2rem
        .description
          width: 100%
          .text
            font-size: 1.25rem
        .start-container
          flex-direction: column
          .calculator
            width: 100%
            margin-bottom: 60px
          .decor-image1
            display: none
          .decor-image2
            display: block
            align-self: center
    .intro
      .content
        grid-template-areas: 'title title' 'steps steps'
        grid-gap: 40px 0
        .intro-image
          display: none
        .steps
          gap: 20px
          .step_item
            gap: 40px
            .image
              width: 99px
              height: 108px
            .step-info
              gap: 20px
              .name
                font-size: 1.5rem
                font-weight: 600
              .text
                font-size: 1.25rem
                font-weight: 400
    .garant
      .content
        .garant-blocks
          flex-direction: column
      .content
        .garant-blocks
          flex-direction: column
          justify-content: unset
          .block
            display: flex
            flex-direction: row
            align-items: center
            gap: 30px
            border-radius: 15px
            border: 2px solid #2C9FFA
            background: #2C9FFA
            .image-cont
              .image
                display: none
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
              .image-ext
                display: block
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
            .text
              max-width: unset
              padding-right: 30px
              color: #FFF
              text-align: center
              font-size: 1.25rem
              font-weight: 600

              // reviews не раюотают как должны из-за ширин колонок
    .reviews
      max-width: 100%
      .content
        display: grid
        grid-gap: 30px 0
        grid-template-areas: 'title nav' 'slider slider'
        .title-text
          grid-area: title
        .nav-btns
          grid-area: nav
          display: flex
          flex-direction: row
          gap: 10px
          justify-content: flex-end
          align-items: flex-end
          .prev
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            background: var(--accent-color)
          .next
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            transform: rotate(180deg)
            background: var(--accent-color)
          .disabled
            background: var(--gray-lines-color)
            cursor: not-allowed
            pointer-events: none
        .slider
          max-width: 100%
          overflow: hidden
          min-width: calc(100% + 68px)
          margin-left: -34px
          padding-left: 34px
          padding-right: 34px
          .swiper-wrapper
            align-items: flex-start
            justify-content: flex-start
          .slide
            max-width: 100%
            height: 100%
            .user-info
              .image
                width: 50px
                height: 50px
              .name
                font-size: 1.25rem
                font-weight: 600
            .text
              max-width: 280px
              font-size: 1rem
    .form
      margin: 50px 0

    .conditions
      padding: 50px 46px 100px
      .content
        padding: 30px
        background: var(--card-color)
        .line
          gap: 10px
          .name
            font-size: 1.25rem
          .text
            font-size: 1rem
@media only screen and (max-width: $bp-tablet)
  .home-page
    .start
      margin-bottom: 40px
      .content
        .title
          font-size: 1rem
          margin-bottom: 20px
        .description
          gap: 15px
          padding: 15px
          margin-bottom: 40px
          .text
            font-size: 0.875rem
        .start-container
          flex-direction: column
          .calculator
            width: unset
            margin-bottom: 40px
          .decor-image1
            display: none
          .decor-image2
            display: block
            align-self: center
    .intro
      .content
        grid-gap: 30px 0
        .steps
          gap: 10px
          .step_item
            gap: 20px
            padding: 20px 10px
            .image
              width: 55px
              height: 60px
            .step-info
              gap: 10px
              .name
                font-size: 1rem
                font-weight: 600
              .text
                font-size: 0.875rem
                font-weight: 400
    .garant
      .content
        .garant-blocks
          flex-direction: column
      .content
        .garant-blocks
          flex-direction: column
          justify-content: unset
          gap: 10px
          .block
            display: flex
            flex-direction: row
            align-items: center
            gap: 10px
            border-radius: 15px
            border: 2px solid #2C9FFA
            background: #2C9FFA
            .image-cont
              width: 46%
              .image
                display: none
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
              .image-ext
                display: block
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
            .text
              max-width: unset
              padding-right: 10px
              color: #FFF
              text-align: center
              font-size: 1rem
              font-weight: 600
    .reviews
      .content
        display: grid
        grid-gap: 30px 0
        grid-template-areas: 'title nav' 'slider slider'
        .title-text
          grid-area: title
        .nav-btns
          grid-area: nav
          display: flex
          flex-direction: row
          gap: 10px
          justify-content: flex-end
          align-items: flex-end
          .prev
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            background: var(--accent-color)
          .next
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            transform: rotate(180deg)
            background: var(--accent-color)
          .disabled
            background: var(--gray-lines-color)
            cursor: not-allowed
            pointer-events: none
        .slider
          .swiper-wrapper
            align-items: flex-start
            justify-content: flex-start
          .slide
            height: 100%
            padding: 20px
            .user-info
              .image
                width: 50px
                height: 50px
              .name
                font-size: 1.25rem
                font-weight: 600
            .text
              max-width: 280px
              font-size: 1rem
    .form
      margin: 50px 0
      background: #E8F3FF
      padding: 50px 0
      .calculator
        background: #E8F3FF
      .form-image
        display: none

    .conditions
      padding: 40px 0 80px
      .content
        padding: 20px
        gap: 20px
        background: var(--card-color)
        .line
          gap: 10px
          .name
            font-size: 1rem
          .text
            font-size: 0.875rem
</style>