import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/pages/HomePage.vue";
import ProductsPage from '@/pages/ProductsPage.vue'

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/zajmy-onlain',
    component: ProductsPage
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

export default router
