<template>
  <div class="wrapper">
    <div class="modal">
      <button @click="closeModal" class="close-btn">
        <img src="@/assets/images/icons/close-btn.svg" alt="">
      </button>
      <div class="text">
        <span>
          Я ваш персональный помощник и помогу подобрать наиболее выгодное предложение.
          Заполните форму, чтобы посмотреть лучшие варианты.
        </span>
      </div>
      <div class="images">
        <img class="robot" src="@/assets/images/robot.webp" alt="">
        <img class="line" src="@/assets/images/line.webp" alt="">
      </div>
      <v-calculator :isModal="isModal"/>
    </div>
  </div>
</template>

<script>
import VCalculator from "@/components/UI/vCalculator.vue";
export default {
  components: {
    VCalculator
  },
  data() {
    return {
      isModal: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass">
.wrapper
  z-index: 100000
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  display: flex
  justify-content: center
  align-items: center
  background: rgba(0, 0, 0, 0.2)
  .modal
    position: relative
    background: var(--start-color)
    border-radius: 20px
    padding: 30px 40px
    display: grid
    width: 1100px
    grid-template-areas: "text text" "images calc"
    grid-template-columns: auto 1fr
    grid-gap: 30px 60px
    .close-btn
      position: absolute
      top: 15px
      right: 20px
      border: none
      background: transparent
      padding: unset
    .text
      grid-area: text
      background: var(--accent-color)
      padding: 20px
      border-radius: 20px
      span
        color: #FFF
        font-size: 1.5rem
        font-weight: 500
    .images
      grid-area: images
      position: relative
      margin-bottom: -30px
      display: flex
      align-items: end
      .robot
        height: 90%
      .line
        position: absolute
        top: 0
        right: 0
    .calculator
      grid-area: calc
</style>