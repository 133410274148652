import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/styles/index.sass"
import {createHead} from "@vueuse/head";

const head = createHead()
const app = createApp(App)

app.use(router).use(head).mount('#app')
