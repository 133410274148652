<template>
    <div class="products-page">
        <div class="content">
            <p class="text">
                <span><span class="colorful">"Займы Онлайн"</span> помогут тебе получить заём быстрее и проще. Заполни
                    несколько
                    анкет и получи одобрение.</span>
            </p>
        </div>

        <div class="best-products">
            <div class="content">
                <h1 class="h1">Лучшие предложения для Вас</h1>
                <div class="products" v-if="allZaymi">
                    <ProductCard v-for="(product, index) in allZaymi" :product="product" :key="index" />
                </div>
                <div class="nothing" v-else>
                  <span class="span">Ничего не найдено...</span>
                  <span class="span">Вы можете рассмотреть наши другие предложения.</span>
                </div>
            </div>
        </div>

        <div class="content" v-if="allZaymi.length !== this.zaymi.length">
            <div class="products products-list">
                <ProductCard v-for="(product, index) in zaymi" :product="product" :key="index" />
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import zaymi from '@/json/zaymi.json'
export default {
  data() {
    return {
      zaymi: zaymi,
      showModal: false,
      allZaymi: []
    }
  },
  bestProducts: ['a'],
  components: { ProductCard },
  mounted() {
    if (this.showModal === false) {
      document.documentElement.style.overflowY = 'auto'
    }
    this.getBest()
  },
  methods: {
    getBest() {
      this.allZaymi = this.zaymi.filter(z => {
        if (z.sum >= this.$route.query.sum) {
          return z
        }
      })
    }
  }
}
</script>

<style lang='sass'>
@import '@/assets/styles/base/adaptive'
.products-page
    padding-top: 200px
    padding-bottom: 150px
    .text
        display: flex
        position: relative
        flex-direction: column
        gap: 15px
        font-size: 1.5rem
        padding: 20px 30px
        font-weight: 400
        .bold
            font-weight: 600
        .colorful
            color: var(--accent-color)
        &::before
            content: ''
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: 6px
            background-color: var(--accent-color)
    .best-products
        margin: 60px 0
        padding: 30px 0 60px
        background-color: #E2F8FF
        .content
            .prompt
                font-size: 1.5rem
                font-weight: 400
            .h1
                font-size: 2.5rem
                font-weight: 600
                margin: 20px 0
        .products
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 20px
        .nothing
            display: flex
            flex-direction: column
            gap: 10px
            .span
                font-size: 1.5rem

    .products-list
          display: grid
          grid-template-columns: repeat(4, 1fr)
          grid-gap: 20px
          .card
              box-shadow: 0 0 16px 0 #00000040
              .btn
                  background: var(--main-color)
@media only screen and (max-width: $bp-pc)
    .products-page
        padding-top: 130px
        padding-bottom: 100px
        .text
            font-size: 1.25rem
            padding: 20px 30px
            gap: 10px
        .best-products
            margin: 30px 0
            padding: 40px 0
            .content
                .prompt
                    font-size: 1.25rem
                .h1
                    font-size: 2.25rem
                    margin: 10px 0
            .products
                grid-template-columns: 1fr 1fr
                margin-top: 20px
        .products-list
            grid-template-columns: 1fr 1fr
@media only screen and (max-width: $bp-tablet)
    .products-page
        padding-bottom: 80px
        .text
            font-size: 0.875rem
            padding: 15px 30px
            gap: 10px
        .best-products
            margin: 20px 0
            padding: 30px 0
            .content
                .prompt
                    font-size: 1rem
                .h1
                    font-size: 1.5rem
            .products
                grid-template-columns: 1fr
        .products-list
            grid-template-columns: 1fr
            margin-top: 30px
</style>