<template>
  <div class="calculator">
    <div class="range">
      <div class="input-block">
        <input class="input" type="number" v-model.number="value" @input="validateValue" @blur="normalizeValue"
          @tabindex="normalizeValue">
        <div class="btns">
          <button class="btn decr" @click="decrement">-</button>
          <button class="btn incr" @click="increment">+</button>
        </div>
      </div>
      <input type="range" min="1000" max="100000" step="1000" v-model="value" @input="validateValue" />
      <div class="range-cost">
        <span>1 000 ₽</span>
        <span>100 000 ₽</span>
      </div>
    </div>
    <div class="question-block" v-if="isModal">
      <div class="checkbox">
        <input type="checkbox" class="check">
        <label for="checkbox" class="label">Впервые беру займ</label>
      </div>
      <div class="checkbox">
        <input type="checkbox" class="check">
        <label for="checkbox" class="label">У меня плохая КИ</label>
      </div>
      <div class="checkbox">
        <input type="checkbox" class="check">
        <label for="checkbox" class="label">Нужен заём с низким процентом</label>
      </div>
    </div>
    <div class="question-block" v-else>
      <span class="question">Получали ли вы займы ранее?</span>
      <div class="select">
        <div>
          <input type="radio" value="Да" v-model="selectedOption">
          <label >Да</label>
        </div>
        <div>
          <input type="radio" value="Нет" v-model="selectedOption" checked="checked">
          <label >Нет</label>
        </div>
      </div>
    </div>
    <button class="btn" @click="$router.push({ path: '/zajmy-onlain', query: { sum: value } })">Получить заём</button>
  </div>
</template>

<script>
export default {
  props: {
    isModal: {
      type: Boolean,
    }
  },
  data() {
    return {
      value: 15000,
      selectedOption: 'Нет'
    }
  },
  methods: {
    validateValue() {
      this.value = Math.max(1000, Math.min(100000, this.value));
    },
    normalizeValue() {
      this.value = Math.round(this.value / 1000) * 1000;
    },
    increment() {
      this.value = Math.min(100000, this.value + 1000);
    },
    decrement() {
      this.value = Math.max(1000, this.value - 1000);
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.calculator
  width: 670px
  display: flex
  flex-direction: column
  padding: 40px 60px
  border-radius: 20px
  gap: 40px
  background: #FFF
  .range
    display: flex
    flex-direction: column
    .input-block
      display: flex
      flex-direction: row
      gap: 5px
      margin-bottom: 20px
      .input
        border: 1px solid #1D87B1
        padding: 10px 10px 10px 20px
        border-radius: 15px
        width: 100%
        color: var(--main-color)
        font-size: 2rem
        font-weight: 500
      .btns
        display: flex
        flex-direction: row
        gap: 5px
        .btn
          border-radius: 20px
          padding: 0 30px
          background: #ABDCEF
          color: #0488D1
          font-size: 3rem
          font-weight: 300
          border: 1px solid #1D87B1
      .input-range
        margin-bottom: 10px

    input[type=range]
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none

    input[type=range]::-webkit-slider-runnable-track
      border-radius: 10px
      height: 10px
      border: 1px solid #2E95DF
      background-color: #2E95DF

    input[type=range]::-webkit-slider-thumb
      background: white
      border: 2px solid #AD00FF
      border-right-color: #0029FF
      border-bottom-color: #0029FF
      width: 50px
      height: 25px
      border-radius: 20px
      cursor: pointer
      -webkit-appearance: none
      margin-top: -8px


    input[type=range]::-moz-range-track
      border-radius: 10px
      height: 7px
      border: 1px solid #2E95DF
      background-color: #2E95DF

    input[type=range]::-moz-range-thumb
      background: white
      border: 2px solid #AD00FF
      border-right-color: #0029FF
      border-bottom-color: #0029FF
      width: 50px
      height: 25px
      border-radius: 20px
      cursor: pointer

    .range-cost
      display: flex
      flex-direction: row
      justify-content: space-between
      padding-top: 10px
      span
        font-size: 1.25rem
        font-weight: 400
  .question-block
    display: flex
    flex-direction: column
    gap: 20px
    .checkbox
      display: flex
      .check
        width: 20px
        height: 20px
        margin: 2px 0 0
        cursor: pointer
      .label
        margin-left: 10px
        color: var(--main-color)
        font-size: 1.25rem
        font-weight: 400
    .question
      font-size: 1.25rem
      font-weight: 500
    .select
      display: flex
      flex-direction: row
      gap: 30px
      div
        display: flex
        flex-direction: row
        gap: 10px
        align-items: center
      input[type="radio"]
        margin: unset
        width: 20px
        height: 20px
      label
        font-size: 1.25rem
        font-weight: 400
        font-style: normal
        line-height: normal
  .btn
    border: unset
    color: white
    font-size: 1.5rem
    font-weight: 600
    padding: 20px
    border-radius: 100px
    background: var(--gradient)
@media only screen and (max-width: $bp-tablet)
  .calculator
    display: flex
    width: 100vw
    padding: 30px 20px
    border-radius: unset
    margin: 0 -20px
    gap: 20px
    background: #FFF
    .range
      display: flex
      flex-direction: column
      .input-block
        display: flex
        flex-direction: row
        gap: 5px
        margin-bottom: 15px
        .input
          padding: 5px 5px 5px 10px
          border-radius: 10px
          width: 100%
          color: var(--main-color)
          font-size: 1.5rem
          font-weight: 500
        .btns
          display: flex
          flex-direction: row
          gap: 5px
          .btn
            border-radius: 10px
            padding: 0 10px
            background: #ABDCEF
            color: #0488D1
            font-size: 2rem
            font-weight: 300
            border: 1px solid #1D87B1
        .input-range
          margin-bottom: 10px

      input[type=range]
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none

      input[type=range]::-webkit-slider-runnable-track
        border-radius: 10px
        height: 7px
        border: 1px solid #2E95DF
        background-color: #2E95DF

      input[type=range]::-webkit-slider-thumb
        background: white
        border: 2px solid #AD00FF
        border-right-color: #0029FF
        border-bottom-color: #0029FF
        width: 36px
        height: 20px
        border-radius: 20px
        cursor: pointer
        -webkit-appearance: none
        margin-top: -8px


      input[type=range]::-moz-range-track
        border-radius: 10px
        height: 5px
        border: 1px solid #2E95DF
        background-color: #2E95DF

      input[type=range]::-moz-range-thumb
        background: white
        border: 2px solid #AD00FF
        border-right-color: #0029FF
        border-bottom-color: #0029FF
        width: 50px
        height: 25px
        border-radius: 20px
        cursor: pointer

      .range-cost
        display: flex
        flex-direction: row
        justify-content: space-between
        padding-top: 10px
        span
          font-size: 1rem
          font-weight: 400
    .question-block
      display: flex
      flex-direction: column
      gap: 15px
      .question
        font-size: 1rem
        font-weight: 500
      .select
        display: flex
        flex-direction: row
        gap: 30px
        div
          display: flex
          flex-direction: row
          gap: 10px
          align-items: center
        input[type="radio"]
          margin: unset
          width: 20px
          height: 20px
        label
          font-size: 1rem
          font-weight: 400
          font-style: normal
          line-height: normal
    .btn
      border: unset
      color: white
      font-size: 1rem
      font-weight: 600
      padding: 10px
      border-radius: 100px
      background: var(--gradient)
</style>